
import { Vue, Component } from 'vue-property-decorator'
import { ProjectDetail, ProjectList } from '../../types/project'
import { KeepAlive } from '@/utils/decorators'

@Component
@KeepAlive
export default class List extends Vue {
  private addrList: object[] = []
  private searchInfo: {projectName: string; addr: string[]; projectType: string; projectState: string} = {
    projectName: '',
    addr: [],
    projectType: '',
    projectState: ''
  }

  private itemList = [
    {
      label: '项目名称',
      prop: 'projectName'
    },
    {
      label: '项目地址',
      prop: 'addr'
    },
    {
      label: '养护开始时间',
      prop: 'maintStartTime'
    },
    {
      label: '养护结束时间',
      prop: 'maintEndTime'
    },
    {
      label: '项目面积',
      prop: 'maintAcreage'
    },
    {
      label: '养护单位',
      prop: 'maintUnit'
    },
    {
      label: '项目类型',
      prop: 'projectTypeName'
    },
    {
      label: '项目状态',
      prop: 'projectState'
    }
  ]

  private tableData: ProjectDetail[] = []
  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private projectTypeList: object[] = []

  created () {
    this.getAddrList()
    this.getList()
    this.getProjectTypeList()
  }

  // 搜索
  onSearch () {
    this.page = 1
    this.getList()
  }

  // 获取区域列表
  getAddrList () {
    this.$axios.get(this.$apis.common.getRegion).then(res => {
      this.addrList = res || []
    })
  }

  // 项目类型
  getProjectTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'projectType' }).then(res => {
      this.projectTypeList = res.projectType || []
    })
  }

  // 获取列表数据
  getList () {
    this.loading = true
    const info = {
      projectName: this.searchInfo.projectName,
      projectProvince: this.searchInfo.addr[0] || '',
      projectCity: this.searchInfo.addr[1] || '',
      projectArea: this.searchInfo.addr[2] || '',
      projectType: this.searchInfo.projectType,
      projectState: this.searchInfo.projectState
    }
    this.$axios.get<ProjectList>(this.$apis.project.selectYhProjectByPage,
      {
        ...info,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }

  // 新增项目
  addProject () {
    this.$router.push({ name: 'projectAdd' })
  }
}
